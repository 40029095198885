<template>
  <div>
    <b-card
      text-variant="center"
      class="card card-congratulations"
    >
      <b-img
        fluid
        :src="require('@/assets/images/cp-dash.png')"
      />
    </b-card>
  </div></template>

<script>
import {
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>
