<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <welcome-card />
      </b-col>
      <b-col
        lg="12"
        md="12"
      >
        <b-card
          text-variant="left"
          class="card"
        >
          <b-card-text class="m-auto w-100">
            <h2 class="text-primary">
              FreeStyle Libre 2 Program
            </h2>
            <blockquote class="blockquote"><p class="mb-0">
              Frequent glucose testing is a fact of life for people living with diabetes. But routine finger pricking doesn’t have to be.
            </p>
            </blockquote>
            <p class="mb-0">
              FreeStyle Libre 2 is a sensor-based glucose monitoring system that replaces routine blood glucose testing,* and also provides actionable insights with more information than a single finger prick:
              <ul>
                <li>Continuously measures glucose for up to 14 days†</li>
                <li>Displays current glucose reading and glucose history</li>
                <li>Displays a trend arrow showing where glucose levels are heading</li>
                <li>Includes optional glucose alarms to keep users informed when glucose is too high or too low</li>
                <li>allow users to share their complete glycaemic profile with their healthcare providers (HCPs) through LibreView.</li>
              </ul>
              This program was designed to upgrade glucose monitoring technology for people with T1 diabetes and assist them with using FreeStyle Libre 2 effectively.

            </p>
            <div class="w-25">

              <b-embed
                type="iframe"
                aspect="16by9"
                src="https://player.vimeo.com/video/393335115?color=E95526"
                allowfullscreen
              />
            </div>
            <footer class="blockquote-footer"><cite>
              <ol>
                <li>*Scanning the sensor to obtain glucose values does not require lancets. Finger pricks are required if your glucose readings and alarms do not match symptoms or expectations.</li>
                <li>The FreeStyle Libre and FreeStyle Libre 2 Flash Glucose Monitoring System is intended for persons (age 4 and older) with insulin-requiring diabetes mellitus. The indication for children (age 4 - 17) is limited to those who are supervised by a caregiver who is at least 18 years of age.
                </li>
                <li>†Sensor must be scanned at least once every 8 hours.</li>
              </ol>
            </cite></footer>

            <h2 class="text-primary">
              Resources for pharmacists
            </h2>
            <blockquote class="blockquote"><p class="mb-0">
                                             Please note this is not for patient distribution. </p>
              <ul>
                <li><a
                  href="https://www.freestyleconnect.com.au"
                  target="_blank"
                >MyInteract</a> </li>
                <li><a
                  href="https://lib-view-instructions.s3.ap-southeast-2.amazonaws.com/Instructions+for+LibreView+Practice+connection.pdf"
                  target="_blank"
                >LibreView – Instructions for practice connection</a> </li>
                <li><a
                  href="https://lib-view-instructions.s3.ap-southeast-2.amazonaws.com/Instructions+to+navigate+to+a+patient's+glucose+report.pdf"
                  target="_blank"
                >LibreView – Instructions to navigate to a patient's glucose report</a> </li>
              </ul>
            </blockquote>

            <h2 class="text-primary">
              Resources for patients
            </h2>
            <blockquote class="blockquote">
              <ul>
                <li><a
                  href="https://www.freestylelibre.com.au"
                  target="_blank"
                >FreeStyle Libre website</a> </li>
                <li><a
                  href="https://www.freestylelibre.com.au/video-guides"
                  target="_blank"
                >Video guides</a> </li>
                <li><a
                  href="https://www.freestylelibre.com.au/product-support"
                  target="_blank"
                >Support resources</a> </li>
                <li><a
                  href="https://www.freestylelibre.com.au/product-support"
                  target="_blank"
                >Consumer booklet</a> </li>
                <li><a
                  href="https://www.freestylelibre.com.au/product-support"
                  target="_blank"
                >LibreLink How to Guide</a> </li>
              </ul>
            </blockquote>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow, BCol, BEmbed, BCard, BCardText,
} from 'bootstrap-vue'
import WelcomeCard from '@/views/apps/my-profile/WelcomePharmacy.vue'

export default {
  components: {
    WelcomeCard,
    BRow,
    BCol,
    BEmbed,
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
